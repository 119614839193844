import React from 'react';
import { Banner, Card, Page, Layout } from '@shopify/polaris';

function Error({
  message,
  description,
}: {
  message?: string;
  description?: string | JSX.Element;
}): JSX.Element {
  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            <Banner title={message} status="critical">
              {description}
            </Banner>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

Error.defaultProps = {
  message: 'Failed to load data',
  description: 'Please try to reload the page',
};

export default Error;
