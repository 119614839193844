import React from 'react';
import { SkeletonPage, Layout, Card, SkeletonBodyText, Stack } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';
import EmptyStateImage from '../../../../../images/shopify-empty-products.svg';

function LoadingSkeleton(): JSX.Element {
  return (
    <SkeletonPage primaryAction>
      <Loading />
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img src={EmptyStateImage} alt="Packify" />
              </div>
              <SkeletonBodyText lines={6} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export default LoadingSkeleton;
