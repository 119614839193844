import { Layout, Page, Text, TextContainer } from '@shopify/polaris';
import React from 'react';

function PrivacyPolicy(): JSX.Element {
  return (
    <Page narrowWidth title="Packify Privacy Policy">
      <Layout>
        <Layout.Section>
          <TextContainer>
            <p>
              Packify ”the App” provides an ability to create Cases or Multi-Packs and to keep their inventory in sync
              ”the Service” to merchants who use Shopify to power their stores. This Privacy Policy describes how
              personal information is collected, used, and shared when you install or use the App in connection with
              your Shopify-supported store.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Personal Information the App Collects
            </Text>
            <p>
              When you install the App, we are automatically able to access certain types of information from your
              Shopify account: locations, products and inventory.
            </p>
            <p>
              Additionally, we collect the following types of personal information from you and/or your customers once
              you have installed the App:
            </p>
            <ul>
              <li>
                Information about individuals who visit your store, such as their IP address, web browser details, time
                zone, and information about the cookies installed on the particular device.
              </li>
            </ul>
            <p>
              We collect personal information directly from the relevant individual, through your Shopify account. “Log
              files” track actions occurring on the Site, and collect data including your IP address, browser type,
              Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels”
              are electronic files used to record information about how you browse the Site.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              How Do We Use Your Personal Information?
            </Text>
            <p>
              We use the personal information we collect from you and your customers in order to provide the Service and
              to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or
              improve the App; and Provide you with information or advertising relating to our products or services.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Sharing Your Personal Information
            </Text>
            <p>
              Finally, we may also share your Personal Information to comply with applicable laws and regulations, to
              respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise
              protect our rights.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Do Not Track
            </Text>
            <p>
              Please note that we do not alter our App’s data collection and use practices when we see a Do Not Track
              signal from your browser.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Your Rights
            </Text>
            <p>
              If you are a European resident, you have the right to access personal information we hold about you and to
              ask that your personal information be corrected, updated, or deleted. If you would like to exercise this
              right, please contact us through the contact information below.
            </p>
            <p>
              Additionally, if you are a European resident we note that we are processing your information in order to
              fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise
              to pursue our legitimate business interests listed above. Additionally, please note that your information
              will be transferred outside of Europe, including to Canada and the United States.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Data Retention
            </Text>
            <p>
              When you place an order through the Site, we will maintain your Order Information for our records unless
              and until you ask us to delete this information.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Changes
            </Text>
            <p>
              We may update this privacy policy from time to time in order to reflect, for example, changes to our
              practices or for other operational, legal or regulatory reasons.
            </p>
          </TextContainer>

          <br />

          <TextContainer>
            <Text variant="headingMd" as="h2">
              Contact Us
            </Text>
            <p>
              For more information about our privacy practices, if you have questions, or if you would like to make a
              complaint, please contact us by e-mail at <a href="mailto:support@packify.app">support@packify.app</a> or
              by mail using the details provided below:
            </p>
            <p>1 Radisson Plaza, Ste 800, New Rochelle, NY, 10801, United States</p>
          </TextContainer>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default PrivacyPolicy;
