import { ClientApplication } from '@shopify/app-bridge';
import { authenticatedFetch } from '@shopify/app-bridge-utils';

class APIError extends Error {
  public status: number | null;

  public response: string | null;

  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.status = null;
    this.response = null;
  }
}

export const authFetch = (
  app: ClientApplication<any>,
): ((url: RequestInfo, options: RequestInit) => Promise<any>) => authenticatedFetch(app);

function handleErrors(response: Response) {
  if (!response.ok) {
    return response.json().then((body) => {
      const message = Object.prototype.hasOwnProperty.call(body, 'message')
        ? body.message
        : response.statusText;
      const error = new APIError(message);
      error.status = response.status;
      error.response = body;
      throw error;
    });
  }
  return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher = (app: ClientApplication<any>) => (
  url: RequestInfo,
  options: RequestInit,
): Promise<any> =>
  authFetch(app)(url, options)
    .then(handleErrors)
    .then((r) => r.json());
