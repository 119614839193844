import React, { useState } from 'react';
import {
  Card,
  DataTable,
  Page,
  Layout,
  Collapsible,
  Button,
  Tooltip,
  Checkbox,
} from '@shopify/polaris';
import { TitleBar, useAppBridge } from '@shopify/app-bridge-react';
import useSWR from 'swr';

import { fetcher } from '../../../lib/Shopify/utils';
import Error from './shared/Error';
import LoadingSkeleton from './Events/LoadingSkeleton';
import Diff, { IDetails } from './Events/Diff';
import Pagination from './shared/Pagination';

type IOpened = Record<string, boolean>;
interface IEvent {
  id: string;
  text: string;
  details: IDetails;
  created_at: string; // eslint-disable-line camelcase
}

function formatEvents(records: IEvent[], opened: IOpened, setOpened: (value: IOpened) => void) {
  return records.map((event: IEvent) => [
    <div key={event.id}>
      <Button
        plain
        disclosure={opened[event.id] ? 'up' : 'down'}
        onClick={() => setOpened({ ...opened, [event.id]: !opened[event.id] })}
      >
        {event.text}
      </Button>
      <Collapsible open={opened[event.id]} id={event.id}>
        <Tooltip content={`Event #${event.id}`}>
          <Diff difference={event.details} />
        </Tooltip>
      </Collapsible>
    </div>,
    event.created_at,
  ]);
}

function Events(): JSX.Element {
  const app = useAppBridge();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [verbose, setVerbose] = useState(false);
  const { data, error } = useSWR(`/api/events?page=${pageIndex}&verbose=${verbose}`, fetcher(app));
  const [opened, setOpened] = useState<IOpened>({});

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <LoadingSkeleton />;
  }

  return (
    <Page>
      <TitleBar title="Audit Log" />
      <Layout>
        <Layout.Section>
          <Card>
            <DataTable
              columnContentTypes={['text', 'text']}
              headings={['Event', 'Time']}
              rows={formatEvents(data.records, opened, setOpened)}
            />
          </Card>
          <div style={{ marginTop: '15px' }}>
            <Checkbox label="Show everything (verbose)" checked={verbose} onChange={setVerbose} />
          </div>
          <Pagination data={data} currentIndex={pageIndex} onChangePage={setPageIndex} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Events;
