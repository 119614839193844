import React from 'react';
import { Loading, useAppBridge } from '@shopify/app-bridge-react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import LoadingSkeleton from './Edit/LoadingSkeleton';
import EditForm from './Edit/Form';

import Error from '../shared/Error';

import { fetcher } from '../../../../lib/Shopify/utils';

function Edit(): JSX.Element {
  const app = useAppBridge();
  const { id } = useParams();
  const { data: product, error, isValidating } = useSWR(`/api/products/${id}`, fetcher(app));
  const { data: settings, error: settingsError } = useSWR('/api/settings', fetcher(app));

  if (error || settingsError) {
    return <Error />;
  }

  if (!product || !settings) {
    return <LoadingSkeleton />;
  }

  return (
    <div>
      {isValidating && <Loading />}
      <EditForm product={product} settings={settings} />
    </div>
  );
}

export default Edit;
