import React from 'react';
import { TextStyle } from '@shopify/polaris';

export type IChangeValue = null | string | Record<string, unknown> | Array<IChangeValue>;

function formatValue(value: IChangeValue): string {
  if (value === null || value === '') {
    return '<blank>';
  }
  if (value instanceof Object) {
    return JSON.stringify(value);
  }

  return String(value);
}

function DiffValue({ value }: { value: IChangeValue }): JSX.Element {
  if (!Array.isArray(value) || value.every((v) => v instanceof Object)) {
    return <TextStyle variation="positive">{formatValue(value)}</TextStyle>;
  }

  const [oldValue, newValue] = value;

  return formatValue(oldValue) === formatValue(newValue) ? (
    <span>{formatValue(newValue)}</span>
  ) : (
    <span>
      <TextStyle variation="negative">{formatValue(oldValue)}</TextStyle>
      {' => '}
      <TextStyle variation="positive">{formatValue(newValue)}</TextStyle>
    </span>
  );
}

export default DiffValue;
