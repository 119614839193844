import React from 'react';
import { ResourceItem, TextStyle, Stack, Thumbnail } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';

import { IProduct } from '../../shared/types';

function Product({
  item,
  navigate,
}: {
  item: IProduct;
  navigate: (url: string) => void;
}): JSX.Element {
  const media = <Thumbnail source={item.image_url || ImageMajor} alt={item.name} />;

  return (
    <ResourceItem
      id={item.id}
      onClick={() => navigate(`/shopify/products/${item.id}`)}
      media={media}
      accessibilityLabel={`View details for ${item.name}`}
    >
      <Stack>
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{item.name}</TextStyle>
          </h3>
          <div>
            {item.variants.length} variants, {item.packs?.length} packs
          </div>
        </Stack.Item>
      </Stack>
    </ResourceItem>
  );
}

export default Product;
