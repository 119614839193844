import React, { useEffect, useState } from 'react';
import { Loading, useAppBridge } from '@shopify/app-bridge-react';
import { ContextualSaveBar } from '@shopify/app-bridge/actions';

function useContextualSaveBar(save: (() => void)[], discard: (() => void)[]) {
  const [shouldSave, setShouldSave] = useState(false);
  const [shouldDiscard, setShouldDiscard] = useState(false);

  useEffect(() => {
    if (shouldSave) {
      save[0]();
      setShouldSave(false);
    }
  }, [shouldSave, ...save]);

  useEffect(() => {
    if (shouldDiscard) {
      discard[0]();
      setShouldDiscard(false);
    }
  }, [shouldDiscard, ...discard]);

  return [() => setShouldSave(true), () => setShouldDiscard(true)];
}

interface IProps {
  isShown: boolean;
  showSaveLoading: boolean;
  save: (() => void)[];
  discard: (() => void)[];
}

function SaveBar({ isShown, showSaveLoading, save, discard }: IProps): JSX.Element | null {
  const app = useAppBridge();
  const options = {
    saveAction: {
      disabled: false,
      loading: showSaveLoading,
    },
    discardAction: {
      disabled: showSaveLoading,
      loading: false,
      discardConfirmationModal: true,
    },
  };

  const [saveBar] = useState(ContextualSaveBar.create(app, options));
  saveBar.set(options, true);

  const [onSave, onDiscard] = useContextualSaveBar(save, discard);

  useEffect(() => {
    const saveUnsub = saveBar.subscribe(ContextualSaveBar.Action.SAVE, onSave);

    const discardUnsub = saveBar.subscribe(ContextualSaveBar.Action.DISCARD, onDiscard);

    return () => {
      saveUnsub();
      discardUnsub();
    };
  }, []);

  if (isShown) {
    saveBar.dispatch(ContextualSaveBar.Action.SHOW);
  } else {
    saveBar.dispatch(ContextualSaveBar.Action.HIDE);
  }

  return showSaveLoading ? <Loading /> : null;
}

export default SaveBar;
