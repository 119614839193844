import React, { useState } from 'react';
import { Card, Page, Layout, ResourceList } from '@shopify/polaris';
import { ResourcePicker, TitleBar, Loading, useAppBridge } from '@shopify/app-bridge-react';
import { Toast } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import LoadingSkeleton from './List/LoadingSkeleton';
import Product from './List/Product';
import EmptyState from './List/EmptyState';
import { IProduct } from '../shared/types';

import Pagination from '../shared/Pagination';
import Error from '../shared/Error';

import { fetcher } from '../../../../lib/Shopify/utils';

interface IShopifyProduct {
  id: string;
}

interface ISelectedShopifyProduct {
  selection: Array<IShopifyProduct>;
}

function List(): JSX.Element {
  const app = useAppBridge();
  const [isOpened, setIsOpened] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const { data, error, isValidating } = useSWR(
    `/api/products?include_created_by_packs=false&include_products_without_packs=false&page=${pageIndex}`,
    fetcher(app),
  );
  const navigate = useNavigate();

  const selectNewProduct = async (selectPayload: ISelectedShopifyProduct) => {
    const toastLoadingNotice = Toast.create(app, {
      message: 'Fetching product...',
      duration: 10000,
    });
    toastLoadingNotice.dispatch(Toast.Action.SHOW);
    const product = selectPayload.selection[0];
    try {
      const response = await fetcher(app)('/api/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'manual',
        body: JSON.stringify({ remote_id: product.id }),
      });
      navigate(`/shopify/products/${response.id}`);
      toastLoadingNotice.dispatch(Toast.Action.CLEAR);
    } catch (e: any) {
      const toastErrorNotice = Toast.create(app, {
        message: e.message || 'An unexpected error ocurred',
        duration: 5000,
        isError: true,
      });
      toastErrorNotice.dispatch(Toast.Action.SHOW);
      setIsOpened(false);
    }
  };

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <LoadingSkeleton />;
  }

  return (
    <Page primaryAction={{ content: 'Create a pack', onAction: () => setIsOpened(true) }}>
      {isValidating && <Loading />}
      <Layout>
        <Layout.Section>
          <TitleBar title="List of packs" />
          <ResourcePicker
            resourceType="Product"
            open={isOpened}
            selectMultiple={false}
            showVariants={false}
            initialQuery="-tag:pack "
            onSelection={selectNewProduct}
            onCancel={() => setIsOpened(false)}
          />
          {data.records.length > 0 ? (
            <div>
              <Card>
                <ResourceList
                  items={data.records}
                  renderItem={(item: IProduct) => <Product item={item} navigate={navigate} />}
                />
              </Card>
              <Pagination data={data} currentIndex={pageIndex} onChangePage={setPageIndex} />
            </div>
          ) : (
            <Card>
              <EmptyState setIsOpened={setIsOpened} />
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default List;
