import React from 'react';
import { SkeletonPage, Layout, Card, SkeletonBodyText } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';

function LoadingSkeleton(): JSX.Element {
  return (
    <SkeletonPage>
      <Loading />
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <SkeletonBodyText lines={4} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export default LoadingSkeleton;
