import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
import NotFound from './pages/NotFound';
import Shopify from './pages/Shopify';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/yfipohs*" element={<Shopify />} />
        <Route path="/shopify*" element={<Shopify />} />
        <Route path="/">
          <Navigate to="/shopify" />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
