import React from 'react';

import DiffValue, { IChangeValue } from './Diff/Value';

export type IDetails = Record<string, IChangeValue>;

function Diff({ difference }: { difference: IDetails }): JSX.Element {
  if (!difference) {
    return <div />;
  }

  return (
    <div>
      {Object.entries(difference).map(([key, value]) => (
        <div key={key} style={{ fontWeight: 'bold' }}>
          {key}: <DiffValue value={value} />
        </div>
      ))}
    </div>
  );
}

export default Diff;
