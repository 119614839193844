/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormLayout, Card, Stack, TextContainer, TextField } from '@shopify/polaris';

import { Controller } from 'react-hook-form';
import { multiVariantProduct, inventoryAvailable, formatValue, calculateProfit, calculateMargin } from './utils';

import { IProductVariant } from '../../../shared/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SingleUnitProduct({ product, control, register, onOpenProductPage }: any): JSX.Element {
  const actions = [];
  if (product.remote_id) {
    const productRemoteId = product.remote_id.split('/').pop() || 'unknown-id';
    actions.push({
      content: 'View product',
      external: true,
      onAction: () => onOpenProductPage(productRemoteId),
    });
  }

  return (
    <Card title="Single Unit Product" actions={actions}>
      <Card.Section>
        <FormLayout>
          <FormLayout.Group>
            <Controller
              name="single_unit_product_display_name"
              control={control}
              defaultValue={product.display_name}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Custom Title (optional)"
                  autoComplete="off"
                  helpText="If you leave this blank, the product name will be used on the pack buttons in your storefront."
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
      {product.variants.map((variant: IProductVariant) => (
        <Card.Section key={variant.id} title={multiVariantProduct(product) ? `Variant: ${variant.name}` : null}>
          <FormLayout>
            <TextContainer>{inventoryAvailable(variant)}</TextContainer>
            <FormLayout.Group>
              <Stack vertical>
                <span>SKU (Stock Keeping Unit)</span>
                <span>{formatValue(variant.sku)}</span>
              </Stack>
              <Stack vertical>
                <span>Barcode (ISBN, UPC, GTIN, etc.)</span>
                <span>{formatValue(variant.barcode)}</span>
              </Stack>
            </FormLayout.Group>
            <FormLayout.Group condensed>
              <Stack vertical>
                <span>Price</span>
                <span>${variant.price}</span>
              </Stack>
              <Stack vertical>
                <span>Cost per item</span>
                <span>{formatValue(variant.cost, { prefix: '$' })}</span>
              </Stack>
              <Stack vertical>
                <span>Margin</span>
                <span>
                  {formatValue(calculateMargin(Number(variant.cost), Number(variant.price)), {
                    suffix: '%',
                  })}
                </span>
              </Stack>
              <Stack vertical>
                <span>Profit</span>
                <span>
                  {formatValue(calculateProfit(Number(variant.cost), Number(variant.price)), {
                    prefix: '$',
                  })}
                </span>
              </Stack>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      ))}
      <input type="hidden" {...register('single_unit_product_id')} defaultValue={product.id} />
    </Card>
  );
}

export default SingleUnitProduct;
