import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Provider, RoutePropagator } from '@shopify/app-bridge-react';
import { AppConfig } from '@shopify/app-bridge';

import Products from './Embedded/Products';
import Settings from './Embedded/Settings';
import Events from './Embedded/Events';
import Error from './Embedded/shared/Error';

function Embedded({ host }: { host: string | null }): JSX.Element {
  const location = useLocation();

  if (!host) {
    return <Error message="Invalid shop domain" />;
  }

  const appConfig: AppConfig = {
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host,
    forceRedirect: true,
  };

  return (
    <Provider config={appConfig}>
      <RoutePropagator location={location} />
      <Routes>
        <Route path="products*" element={<Products />} />
        <Route path="events" element={<Events />} />
        <Route path="settings" element={<Settings host={host} />} />
      </Routes>
    </Provider>
  );
}

export default Embedded;
