import React, { useCallback, useEffect, useState } from 'react';
import { Form, Page, Layout, PageActions, Tabs, Card, Banner } from '@shopify/polaris';
import { TitleBar, useAppBridge } from '@shopify/app-bridge-react';
import { Redirect, Toast } from '@shopify/app-bridge/actions';
import { useFieldArray, useForm } from 'react-hook-form';
import { mutate } from 'swr';
import * as uuid from 'uuid';

import { Link } from 'react-router-dom';
import SingleUnitProduct from './Form/SingleUnitProduct';
import PackProduct from './Form/PackProduct';
import SaveBar from './Form/SaveBar';
import { IProduct, IProductVariant } from '../../shared/types';
import { isBlank } from './Form/utils';

import { fetcher } from '../../../../../lib/Shopify/utils';

interface IFormData extends IProduct {
  single_unit_product_id: string; // eslint-disable-line camelcase
  single_unit_product_display_name: string; // eslint-disable-line camelcase
}

function EditForm({ product, settings }: { product: IProduct; settings: any }): JSX.Element {
  const app = useAppBridge();
  const [saving, setSaving] = useState(false);
  const { control, register, reset, handleSubmit, setValue, getValues, formState } = useForm({
    defaultValues: {
      ...product,
      single_unit_product_id: product.id,
      single_unit_product_display_name: product.display_name,
    },
    mode: 'all',
  });
  const { fields, append, remove } = useFieldArray<any, any, 'collectionId'>({
    control,
    name: 'packs',
    keyName: 'collectionId',
  });
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    reset({
      single_unit_product_id: product.id,
      single_unit_product_display_name: product.display_name,
      packs: product.packs,
    });
    document.addEventListener('wheel', (event) => {
      if (document.activeElement instanceof HTMLInputElement) {
        if (document.activeElement.type === 'number') {
          document.activeElement.blur();
        }
      }
    });

    if (isBlank(fields[selected - 1])) {
      // The selected tab no longer exists
      setSelected(0);
    }
  }, [reset, product]);
  const { isDirty, dirtyFields, errors } = formState;

  const redirect = Redirect.create(app);

  const openProductPage = (id: string) =>
    redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      section: {
        name: Redirect.ResourceType.Product,
        resource: {
          id,
        },
      },
    });

  const prepareData = (formData: IFormData): any => {
    if (!formData.packs) {
      return {};
    }

    const packs = formData.packs.map((pack) => ({
      ...pack,
      pack_product: {
        ...pack.pack_product,
        variants: pack.pack_product.variants.filter(
          (variant) => variant.enabled === true || variant.enabled === undefined,
        ),
      },
    }));
    return JSON.stringify({
      ...formData,
      packs,
    });
  };

  const onSubmit = async (formData: IFormData) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      setSaving(true);
      const toastLoadingNotice = Toast.create(app, {
        message: 'Saving packs...',
        duration: 10000,
      });
      toastLoadingNotice.dispatch(Toast.Action.SHOW);
      await fetcher(app)('/api/product_packs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'manual',
        body: prepareData(formData),
      });
      toastLoadingNotice.dispatch(Toast.Action.CLEAR);
      const toastSuccessNotice = Toast.create(app, {
        message: 'Product and its packs have been saved.',
        duration: 5000,
      });
      toastSuccessNotice.dispatch(Toast.Action.SHOW);
      await mutate(`/api/products/${formData.single_unit_product_id}`);
    } catch (e: any) {
      const toastErrorNotice = Toast.create(app, {
        message: e.message || 'An unexpected error ocurred',
        duration: 5000,
        isError: true,
      });
      toastErrorNotice.dispatch(Toast.Action.SHOW);
    } finally {
      setSaving(false);
    }
  };

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'single-unit-product',
      content: 'Single Unit Product',
    },
    ...fields.map((item: any, index) => {
      let tabName = item.pack_qty ? `${item.pack_qty} Pack` : 'New Pack';
      if (dirtyFields.packs && !isBlank(dirtyFields.packs[index])) {
        tabName += ' *';
      }
      return {
        id: `pack-${index}`,
        content: tabName,
      };
    }),
  ];

  const lastPack: any = fields[fields.length - 1];

  const onAdd = () => {
    append({
      id: uuid.v4(),
      pack_qty: undefined,
      pack_product: {
        id: uuid.v4(),
        name: '',
        display_name: '',
        remote_id: '',
        images: product.images,
        tracks_inventory: product.tracks_inventory,
        variants: product.variants.map((variant: IProductVariant) => ({
          enabled: true,
          id: uuid.v4(),
          name: variant.name,
          image: variant.image,
          tracks_inventory: variant.tracks_inventory,
          inventory_levels: [],
          barcode: '',
          sku: '',
          price: '',
          cost: '',
          remote_id: '',
        })),
      },
    });
    setSelected(fields.length + 1);
  };

  const onRemove = (index: number) => {
    remove(index);
    setSelected(selected - 1);
  };

  return (
    <Page
      breadcrumbs={[{ content: 'Products', url: '/shopify/products' }]}
      title={product.name}
      primaryAction={{
        content: '➕ Add new pack',
        disabled: lastPack && isBlank(lastPack.pack_product.remote_id),
        primary: false,
        onAction: onAdd,
      }}
    >
      <Layout>
        <TitleBar title={`Packs for ${product.name}`} />
        <SaveBar
          isShown={isDirty}
          showSaveLoading={saving}
          save={[() => onSubmit(getValues())]}
          discard={[
            () => {
              reset(product);
              setSelected(0);
            },
          ]}
        />
        <Layout.Section fullWidth>
          <Card>
            <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                {selected === 0 ? (
                  <SingleUnitProduct
                    product={product}
                    control={control}
                    register={register}
                    onOpenProductPage={openProductPage}
                  />
                ) : (
                  <PackProduct
                    key={fields[selected - 1].collectionId}
                    item={fields[selected - 1]}
                    index={selected - 1}
                    control={control}
                    errors={errors}
                    onOpenProductPage={openProductPage}
                    remove={onRemove}
                    product={product}
                    pack={product.packs?.[selected - 1]}
                    setValue={setValue}
                    fields={fields}
                    settings={settings}
                  />
                )}
              </Tabs>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Banner status="info">
            <p>Don&apos;t forget to configure your theme to display packs on single-unit product pages!</p>
            <p>
              <Link to="/shopify/settings">Go to Settings</Link>
            </p>
          </Banner>
          <PageActions
            primaryAction={{
              content: 'Save',
              disabled: !isDirty || Object.keys(errors).length > 0,
              loading: saving,
              onAction: () => onSubmit(getValues()),
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default EditForm;
