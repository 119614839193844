import React from 'react';
import { Pagination } from '@shopify/polaris';

interface IPagy {
  page: number;
  pages: number;
}

interface IData {
  pagy: IPagy;
}

function EventsPagination({
  data,
  currentIndex,
  onChangePage,
}: {
  data: IData;
  currentIndex: number;
  onChangePage: (index: number) => void;
}): JSX.Element {
  return (
    <div style={{ marginTop: '15px' }}>
      <Pagination
        label={
          <div>
            Page: {data.pagy.page}/{data.pagy.pages}
          </div>
        }
        hasPrevious={data.pagy.page > 1}
        onPrevious={() => onChangePage(currentIndex - 1)}
        hasNext={data.pagy.pages > data.pagy.page}
        onNext={() => onChangePage(currentIndex + 1)}
      />
    </div>
  );
}

export default EventsPagination;
