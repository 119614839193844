import { Layout, Page, Card, Stack, TextContainer, Collapsible, Button } from '@shopify/polaris';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import CreateAPackImage from '../../images/create-a-pack.png';
import EnterPackQtyImage from '../../images/enter-pack-qty.png';
import ProductsPageCustomizationImage from '../../images/products-page-customization.png';
import AddPackifyBlockImage from '../../images/add-packify-block.png';
import CustomizePackifyBlockImage from '../../images/customize-packify-block.png';
import EditThemeImage from '../../images/edit-theme.png';
import InsertCodeImage from '../../images/insert-code.png';
import DisableSalesChannelImage from '../../images/disable-sales-channel.png';
import CreateCollection1Image from '../../images/create-collection-1.png';
import CreateCollection2Image from '../../images/create-collection-2.png';

function FAQ(): JSX.Element {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);

  return (
    <Page title="Frequently Asked Questions">
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Stack vertical>
              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open1}
                ariaControls="basic-collapsible"
                disclosure={open1 ? 'up' : 'down'}
                onClick={() => setOpen1((val: boolean) => !val)}
              >
                How to add a new case/pack with Packify?
              </Button>
              <Collapsible
                open={open1}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    If you have a product that you want to sell in packs, please click on the <b>Create a pack</b>{' '}
                    button and select the product.
                  </p>
                  <p>
                    <img src={CreateAPackImage} width="100%" alt="Create a Pack button" />
                  </p>
                  <p>
                    Next, you can click on the <b>Add new pack</b> button and enter the <b>Quantity in the pack</b>{' '}
                    (e.g., 6, 10, 24, etc.). Finally, click on the <b>Save</b> button.
                  </p>
                  <p>
                    <img src={EnterPackQtyImage} width="100%" alt="Enter pack quantity" />
                  </p>
                  <p>
                    The newly created pack is available on the Products page in Admin, where you can upload additional
                    images or manage collections.
                  </p>
                </TextContainer>
              </Collapsible>

              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open2}
                ariaControls="basic-collapsible"
                disclosure={open2 ? 'up' : 'down'}
                onClick={() => setOpen2((val: boolean) => !val)}
              >
                Can I link an existing pack that we already have created?
              </Button>
              <Collapsible
                open={open2}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    Please get in touch with us at <a href="mailto:support@packify.app">support@packify.app</a>, and we
                    will get you covered.
                  </p>
                </TextContainer>
              </Collapsible>

              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open3}
                ariaControls="basic-collapsible"
                disclosure={open3 ? 'up' : 'down'}
                onClick={() => setOpen3((val: boolean) => !val)}
              >
                How to import thousands of packs from a CSV?
              </Button>
              <Collapsible
                open={open3}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    Please get in touch with us at <a href="mailto:support@packify.app">support@packify.app</a>, and we
                    will get you covered.
                  </p>
                </TextContainer>
              </Collapsible>

              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open4}
                ariaControls="basic-collapsible"
                disclosure={open4 ? 'up' : 'down'}
                onClick={() => setOpen4((val: boolean) => !val)}
              >
                How to configure my Online Store 2.0 theme to display packs on the product page?
              </Button>
              <Collapsible
                open={open4}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    Please go to the Themes page in Admin, and click <b>Customize</b> near your them. Next, select the{' '}
                    <b>Products</b> page on the Theme Editor page.
                  </p>
                  <p>
                    <img src={ProductsPageCustomizationImage} width="100%" alt="Products page customization" />
                  </p>
                  <p>
                    Now, click on the <b>Add block</b> link and find the <b>Packs</b> block.
                  </p>
                  <p>
                    <img src={AddPackifyBlockImage} width="100%" alt="Add Packify block" />
                  </p>
                  <p>Finally, you can customize the look and feel of the block and its position on the page.</p>
                  <p>
                    <img src={CustomizePackifyBlockImage} width="100%" alt="Customize Packify block" />
                  </p>
                </TextContainer>
              </Collapsible>

              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open5}
                ariaControls="basic-collapsible"
                disclosure={open5 ? 'up' : 'down'}
                onClick={() => setOpen5((val: boolean) => !val)}
              >
                How to configure my legacy, non Online Store 2.0 theme to display packs on the product page?
              </Button>
              <Collapsible
                open={open5}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    Please go to the Themes page in Admin, and click <b>Edit code</b> link.
                  </p>
                  <p>
                    <img src={EditThemeImage} width="100%" alt="Edit theme" />
                  </p>
                  <p>
                    Please find the file that renders your product page among the theme files. It could be named
                    differently, e.g., <b>product-template.liquid</b>
                  </p>
                  <p>
                    <img src={InsertCodeImage} width="100%" alt="Insert Packify block code" />
                  </p>
                  <p>
                    Finally, find the Packify block code on the <Link to="/shopify/settings">Settings</Link> page and
                    insert it in your product template.
                  </p>
                </TextContainer>
              </Collapsible>

              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open6}
                ariaControls="basic-collapsible"
                disclosure={open6 ? 'up' : 'down'}
                onClick={() => setOpen6((val: boolean) => !val)}
              >
                How to sell only in packs but track inventory on the single unit level?
              </Button>
              <Collapsible
                open={open6}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    You can disable the &quot;Online Store&quot; sales channel for the products you don&apos;t want to
                    sell on the storefront. To do that, please go to the Products page in Admin, and select the product.
                    Next, click on the <b>Manage</b> link near the <b>SALES CHANNELS AND APPS</b> section and uncheck
                    the &quot;Online Store&quot; sales channel. After doing that, the product won&apos;t be visible to
                    customers anymore, but Packify will continue managing its inventory as usual.
                  </p>
                  <p>
                    <img src={DisableSalesChannelImage} width="100%" alt="Disable Sales Channel" />
                  </p>
                </TextContainer>
              </Collapsible>

              <Button
                removeUnderline
                plain
                size="large"
                ariaExpanded={open7}
                ariaControls="basic-collapsible"
                disclosure={open7 ? 'up' : 'down'}
                onClick={() => setOpen7((val: boolean) => !val)}
              >
                How to hide packs from the storefront and make them available only from the single unit product pages?
              </Button>
              <Collapsible
                open={open7}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
              >
                <TextContainer>
                  <p>
                    You can hide the packs from the storefront by excluding them from the collections that are displayed
                    on the storefront. To do that, please go to the Products page in Admin, and select{' '}
                    <b>Collections</b>. Next, click on the <b>Create collection</b> button and give the collection a
                    name, e.g., <b>Hidden packs</b>.
                  </p>
                  <p>
                    Next, select the <b>Automatic</b> collection type and set the condition to{' '}
                    <b>Product title does not contain Pack</b>. After that, click the <b>Edit</b> link in the{' '}
                    <b>Search engine listing</b> section and set the <b>Handle</b> to <b>all</b>.
                  </p>
                  <p>
                    Finally, click the <b>Save</b> button. The packs will be hidden from the storefront, but they will
                    still be available from the single unit product pages.
                  </p>
                  <p>
                    <img src={CreateCollection1Image} width="100%" alt="Create collection 1" />
                  </p>
                  <p>
                    <img src={CreateCollection2Image} width="100%" alt="Create collection 2" />
                  </p>
                </TextContainer>
              </Collapsible>
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default FAQ;
