import React from 'react';
import { Banner, EmptyState } from '@shopify/polaris';

import EmptyStateImage from '../../../../../images/shopify-empty-products.svg';

function ListEmptyState({ setIsOpened }: { setIsOpened: (opened: boolean) => void }): JSX.Element {
  return (
    <div>
      <EmptyState
        heading="Sell your products in packs"
        action={{
          content: 'Create a pack',
          onAction: () => setIsOpened(true),
        }}
        image={EmptyStateImage}
        imageContained
      >
        <div style={{ textAlign: 'left' }}>
          <p>1. Select an existing product from your catalog that you want to sell in packs.</p>
          <p>2. Enter pack quantity (aka multiplier), pack name, and price.</p>
          <p>3. Packify creates the pack as a regular product on Shopify with its own page.</p>
          <p>4. Configure your Theme to display packs on single-unit product pages.</p>
          <br />
          <br />
          <Banner status="info" title="Need to import a lot of packs?">
            Please contact us at <a href="mailto:support@packify.app">support@packify.app</a>
          </Banner>
        </div>
      </EmptyState>
    </div>
  );
}

export default ListEmptyState;
