/* eslint-disable react/prop-types */
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, FooterHelp } from '@shopify/polaris';
import { createApp } from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';

import '@shopify/polaris/build/esm/styles.css';

import Install from './Shopify/Install';
import PrivacyPolicy from './Shopify/PrivacyPolicy';
import FAQ from './Shopify/FAQ';
import Embedded from './Shopify/Embedded';

function Shopify(): JSX.Element {
  const params = new URLSearchParams(window.location.search);
  let shop = params.get('shop');
  const host = params.get('host');

  if (window.location.pathname === '/shopify') {
    if (host) {
      if (isShopifyEmbedded()) {
        // Embedded inside an iframe
        const app = createApp({
          apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
          host,
          forceRedirect: false,
        });
        Redirect.create(app).dispatch(Redirect.Action.APP, '/shopify/products');
      } else {
        shop = shop || `${atob(host).split('/').pop()}.myshopify.com`;
        window.location.assign(`/shopify/install?shop=${shop}`);
      }
    } else {
      window.location.assign('/shopify/install');
    }
    return <div />;
  }

  const linkComponent = (props: any) => {
    if (props.external) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <a href={props.url} {...props} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      );
    }
    return (
      <Link className={props.className} to={props.url}>
        {props.children}
      </Link>
    );
  };

  return (
    <AppProvider i18n={enTranslations} linkComponent={linkComponent}>
      <Routes>
        <Route path="install" element={<Install />} />
        <Route path="privacy_policy" element={<PrivacyPolicy />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="*" element={<Embedded host={host} />} />
      </Routes>
      <FooterHelp>
        Any questions? Check our{' '}
        <a href="https://packify.cronitorstatus.com" target="_blank" rel="noreferrer">
          status page
        </a>{' '}
        or <a href="mailto:support@packify.app">email us</a>
      </FooterHelp>
    </AppProvider>
  );
}

export default Shopify;
