import React from 'react';
import { Link } from 'react-router-dom';

function NotFound(): JSX.Element {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <h1>Page Not found</h1>
      <p>Sorry, but the page you were trying to view does not exist.</p>
      <p>
        <Link to="/">Go to homepage</Link>
      </p>
      <p>
        <a href="mailto:support@packify.app">Need help?</a>
      </p>
    </div>
  );
}

export default NotFound;
