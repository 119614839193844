import React from 'react';
import { SkeletonPage, Layout, Card, SkeletonBodyText, Tabs } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';

function LoadingSkeleton(): JSX.Element {
  const tabs = [
    {
      id: 'single-unit-product',
      content: 'Single Unit Product',
    },
    {
      id: 'pack-0',
      content: 'Pack 1',
    },
    {
      id: 'pack-1',
      content: 'Pack 2',
    },
  ];

  return (
    <SkeletonPage primaryAction>
      <Loading />
      <Layout>
        <Layout.Section fullWidth>
          <Card>
            <Tabs tabs={tabs} selected={0}>
              <Card.Section>
                <SkeletonBodyText lines={8} />
              </Card.Section>
            </Tabs>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export default LoadingSkeleton;
