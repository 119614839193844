import React, { useState } from 'react';
import { Button, FormLayout, Page, Layout, TextField } from '@shopify/polaris';

function Install(): JSX.Element {
  const [url, setUrl] = useState('');

  const install = () => {
    if (url.length > 1) {
      window.location.assign(`/auth/shopify?shop=${url}.myshopify.com`);
    }
  };

  const params = new URLSearchParams(window.location.search);
  const shop = params.get('shop');
  if (shop) {
    window.location.assign(`/auth/shopify?shop=${shop}`);
    return <div />;
  }

  return (
    <Page narrowWidth title="Packify Installation">
      <Layout>
        <Layout.Section>
          <FormLayout>
            <TextField
              label="Shopify Store URL"
              autoComplete="off"
              value={url}
              suffix=".myshopify.com"
              onChange={(newUrl) => setUrl(newUrl)}
            />
            <Button onClick={() => install()} primary fullWidth>
              Install
            </Button>
          </FormLayout>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Install;
