import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Card,
  Page,
  Layout,
  Form,
  FormLayout,
  PageActions,
  TextStyle,
  TextField,
  Checkbox,
  ButtonGroup,
  Button,
  Stack,
} from '@shopify/polaris';
import { TitleBar, useAppBridge } from '@shopify/app-bridge-react';
import { Toast } from '@shopify/app-bridge/actions';
import useSWR from 'swr';

import { fetcher } from '../../../lib/Shopify/utils';
import Error from './shared/Error';
import LoadingSkeleton from './Settings/LoadingSkeleton';

function Settings({ host }: { host: string }): JSX.Element {
  const app = useAppBridge();
  const [saving, setSaving] = useState(false);
  const { data, error } = useSWR(`/api/settings`, fetcher(app));

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: data,
    mode: 'all',
  });
  const [themeCode, setThemeCode] = useState(
    '{% assign packs = product.selected_or_first_available_variant.metafields.packify.packs.value %}\r\n\r\n{% if packs != blank %}\r\n<div class="packify_wrapper">\r\n  <div class="packify_heading">\r\n    Buy a case and save money\r\n  </div>\r\n  {% for pack in packs %}\r\n    {% if pack.current != true %}\r\n      <div class="packify_pack">\r\n        <a href="{{ pack.url }}" class="packify_pack_link">\r\n          <span class="packify_pack_name">\r\n            {{pack.name}}\r\n          </span>\r\n          <span class="packify_pack_price">{{ pack.price | times: 100 | money }}</span>\r\n        </a>\r\n      </div>\r\n    {% endif %}\r\n  {% endfor %}\r\n</div>\r\n{% endif %}\r\n\r\n<style>\r\n  .packify_heading {\r\n    font-weight: bold;\r\n    color: #000000;\r\n  }\r\n\r\n  .packify_pack_name {\r\n    font-weight: bold;\r\n  }\r\n\r\n  .packify_pack_link {\r\n    color: #000000;\r\n    background-color: #ffffff;\r\n  }\r\n\r\n  .packify_pack_button {\r\n    border: 0;\r\n    border-radius: 0;\r\n    box-shadow: 0 0 0 .1rem;\r\n    cursor: pointer;\r\n    font-size: 1.5rem;\r\n    margin-bottom: 1rem;\r\n    padding: .9rem 3rem 1.1rem;\r\n    min-height: 4.5rem;\r\n    width: 100%;\r\n    -webkit-appearance: none;\r\n  }\r\n\r\n  .packify_pack_button:hover {\r\n    box-shadow: 0 0 0 .2rem;\r\n  }\r\n\r\n  .packify_pack_button:disabled {\r\n    cursor: not-allowed;\r\n    opacity: .5;\r\n  }\r\n</style>',
  );
  const [copiedFields, setCopiedFields] = useState(data ? data.product_fields_to_copy : []);
  useEffect(() => {
    reset(data);
    setCopiedFields(data ? data.product_fields_to_copy : []);
  }, [reset, setCopiedFields, data]);

  const handleChangeThemeCode = useCallback((newValue) => setThemeCode(newValue), []);

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <LoadingSkeleton />;
  }

  const ALLOWED_FIELDS_TO_COPY = [
    ['tags', 'A comma separated list of tags associated with the product. Packs will have an additional "pack" tag.'],
    ['description_html', 'The description of the product, complete with HTML formatting.'],
    ['gift_card_template_suffix', 'The theme template used when viewing the gift card in a store.'],
    ['product_type', 'The product type specified by the merchant.'],
    ['requires_selling_plan', 'Whether the product can only be purchased with a selling plan (subscription).'],
    ['status', 'The product status. This controls visibility across all channels.'],
    ['template_suffix', 'The theme template used when viewing the product in a store.'],
    ['vendor', "The name of the product's vendor."],
    ['seo', 'SEO information of the product.'],
    ['images', 'The images to associate with the product.'],
    ['price', 'The price of the product variant (the pack price is set to single unit price multiplied by pack qty).'],
    ['cost', 'The unit cost of the inventory item (the pack cost is set to single unit cost multiplied by pack qty).'],
    [
      'compare_at_price',
      'The compare at price of the product variant (the pack compare at price is set to single unit compare at price multiplied by pack qty).',
    ],
  ];

  const onSubmit = async (formData: any) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      setSaving(true);
      await fetcher(app)('/api/settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'manual',
        body: JSON.stringify({ ...formData, product_fields_to_copy: copiedFields }),
      });
      const toastNotice = Toast.create(app, {
        message: 'Settings have been saved.',
        duration: 5000,
      });
      toastNotice.dispatch(Toast.Action.SHOW);
    } catch (e) {
      const toastNotice = Toast.create(app, {
        message: e.message || 'An unexpected error ocurred',
        duration: 5000,
        isError: true,
      });
      toastNotice.dispatch(Toast.Action.SHOW);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Page>
      <TitleBar title="Settings" />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={handleSubmit((values) => onSubmit(values))}>
              <Card.Section title="Theme Configuration">
                {data.use_modern_theme ? (
                  <div>
                    <p>Your theme supports App Blocks and can be configured to display packs with a few clicks.</p>
                    <ol>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://${atob(
                            host,
                          )}/themes/current/editor?template=product&addAppBlockId=40c426ff-8aff-4a09-beaf-8182b5cf83f1/app-block&target=mainSection`}
                        >
                          Go to Theme Editor
                        </a>
                      </li>
                      <li>Select the default product template</li>
                      <li>Click on &quot;Add block&quot; and select the Packs block from the Packify app</li>
                      <li>Select the newly added block, adjust heading and colors as necessary</li>
                      <li>Save the theme</li>
                    </ol>
                  </div>
                ) : (
                  <div>
                    <p>
                      Copy the following code and paste it above the &quot;Add to cart&quot; button in your
                      &quot;product-form.liquid&quot; file. If you need any assistance doing that, please reach out to
                      us at <a href="mailto:support@packify.app">support@packify.app</a>.
                    </p>
                    <p>
                      <TextField
                        label=""
                        autoComplete="off"
                        multiline={4}
                        monospaced
                        readOnly
                        onChange={handleChangeThemeCode}
                        value={themeCode}
                      />
                    </p>
                  </div>
                )}
              </Card.Section>

              <Card.Section title="Default discount on new packs">
                <FormLayout>
                  <p>Packify populates the price of the new packs and can apply the discount automatically.</p>
                  <Controller
                    name="pack_discount"
                    control={control}
                    defaultValue={data.pack_discount}
                    render={({ field: { onChange, value } }) => (
                      <Stack alignment="center">
                        <ButtonGroup segmented>
                          <Button pressed={value === '0.00'} onClick={() => onChange('0.00')}>
                            0%
                          </Button>
                          <Button pressed={value === '5.00'} onClick={() => onChange('5.00')}>
                            5%
                          </Button>
                          <Button pressed={value === '10.00'} onClick={() => onChange('10.00')}>
                            10%
                          </Button>
                          <Button
                            pressed={!['0.00', '5.00', '10.00'].includes(value)}
                            onClick={() => onChange('15.00')}
                          >
                            Custom
                          </Button>
                        </ButtonGroup>
                        {!['0.00', '5.00', '10.00'].includes(value) && (
                          <TextField
                            type="number"
                            label={false}
                            suffix="%"
                            value={value}
                            onChange={onChange}
                            autoComplete="off"
                          />
                        )}
                      </Stack>
                    )}
                  />
                </FormLayout>
              </Card.Section>

              <Card.Section title="Notifications">
                <FormLayout>
                  <Controller
                    name="notifications_enabled"
                    control={control}
                    defaultValue={data.notifications_enabled}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label="Receive notifications when packs or single unit products are sold"
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    name="notifications_email"
                    control={control}
                    defaultValue={data.notifications_email}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Email for notifications"
                        autoComplete="off"
                        type="email"
                        value={value}
                        error={errors?.notifications_email && 'should not be blank'}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title="Keep product fields in sync between single-unit products and packs">
                <div>
                  Every time a field from the list below is changed on a single-unit product, all its pack products will
                  be updated with the same value.
                </div>
                <br />
                <div>
                  <FormLayout>
                    {ALLOWED_FIELDS_TO_COPY.map(([productField, productFieldDescription]) => (
                      <Checkbox
                        key={productField}
                        label={
                          <div>
                            <TextStyle variation="code">{productField}</TextStyle>
                            {' - '}
                            {productFieldDescription}
                          </div>
                        }
                        checked={copiedFields.includes(productField)}
                        onChange={(isChecked: boolean) => {
                          const copiedFieldsAfterChange = copiedFields.concat();
                          if (isChecked) {
                            copiedFieldsAfterChange.push(productField);
                          } else {
                            const i = copiedFieldsAfterChange.indexOf(productField);
                            copiedFieldsAfterChange.splice(i, 1);
                          }
                          setCopiedFields(copiedFieldsAfterChange);
                        }}
                      />
                    ))}
                  </FormLayout>
                </div>
              </Card.Section>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: 'Save',
              disabled: Object.keys(errors).length > 0,
              loading: saving,
              onAction: () => onSubmit(getValues()),
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Settings;
