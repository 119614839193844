import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout, Button } from '@shopify/polaris';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import useSWR from 'swr';

import Edit from './Products/Edit';
import List from './Products/List';
import Error from './shared/Error';
import { fetcher } from '../../../lib/Shopify/utils';
import LoadingSkeleton from './Products/Edit/LoadingSkeleton';

function Products(): JSX.Element {
  const app = useAppBridge();
  const { data, error } = useSWR(`/api/subscriptions/status`, fetcher(app));
  const [subscriptionBeingCreated, setSubscriptionBeingCreated] = useState(false);

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <LoadingSkeleton />;
  }

  if (data && !data.valid) {
    const onSubscribe = async () => {
      setSubscriptionBeingCreated(true);
      try {
        const response = await fetcher(app)('/api/subscriptions', {
          method: 'POST',
        });
        Redirect.create(app).dispatch(Redirect.Action.REMOTE, response.confirmation_url);
      } catch {
        setSubscriptionBeingCreated(false);
      }
    };

    return (
      <Layout>
        <Layout.Section>
          <Error
            message="App subscription not active"
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            description={
              <div>
                Please subscribe to a free trial to use the app
                <br />
                <Button onClick={onSubscribe} disabled={subscriptionBeingCreated} loading={subscriptionBeingCreated}>
                  Subscribe
                </Button>
              </div>
            }
          />
        </Layout.Section>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path=":id" element={<Edit />} />
    </Routes>
  );
}

export default Products;
