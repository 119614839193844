import { IProduct, IProductVariant, IInventoryLevel } from '../../../shared/types';

export const multiVariantProduct = (product: IProduct): boolean => product.variants.length > 1;

export const round = (value: number, decimals: number): number =>
  Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);

export const calculateMargin = (cost: number, price: number): number | null => {
  if (!cost || !price) {
    return null;
  }
  return round((1 - cost / price + Number.EPSILON) * 100, 2);
};

export const calculateProfit = (cost: number, price: number): number | null => {
  if (!cost || !price) {
    return null;
  }
  return round(price - cost + Number.EPSILON, 2);
};

export const isBlank = (value: unknown): boolean => !value || value === '';

export const formatValue = (
  value: unknown,
  { prefix, suffix }: { prefix?: string; suffix?: string } = {},
): string => (isBlank(value) ? '-' : `${prefix || ''}${value}${suffix || ''}`);

export const inventoryAvailable = (variant: IProductVariant): string => {
  if (!variant.tracks_inventory) {
    return '📦 Inventory not tracked';
  }

  const inventoryLevels = variant.inventory_levels || [];
  const total = inventoryLevels.reduce((sum: number, level: IInventoryLevel) => sum + level.qty, 0);

  return `📦 ${total} available at ${inventoryLevels.length} locations`;
};
