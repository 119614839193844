/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FormLayout, TextField, Card, Stack, TextContainer, Checkbox, Subheading } from '@shopify/polaris';
import { useWatch, Controller } from 'react-hook-form';

import {
  multiVariantProduct,
  inventoryAvailable,
  formatValue,
  calculateProfit,
  calculateMargin,
  round,
  isBlank,
} from './utils';

import { IProductVariant } from '../../../shared/types';

const CurrencyRegex = /^-?\d+(?:\.\d+)?$/;

const newPack = (product: any) => !product.remote_id;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PackProduct({
  item,
  index,
  control,
  errors,
  onOpenProductPage,
  remove,
  product,
  setValue,
  fields,
  settings,
}: any): JSX.Element {
  const currentValues = useWatch({
    control,
    name: 'packs',
    defaultValue: fields,
  });

  const actions = [];
  if (!newPack(item.pack_product)) {
    const productRemoteId = item.pack_product.remote_id.split('/').pop() || 'unknown-id';
    actions.push({
      content: 'View product',
      external: true,
      onAction: () => onOpenProductPage(productRemoteId),
    });
  }
  actions.push({ content: '❌ Delete', onAction: () => remove(index) });

  return (
    <Card title={`Pack Product #${index + 1}`} actions={actions}>
      <Card.Section>
        <Controller
          render={({ field }) => <input type="hidden" {...field} />}
          name={`packs[${index}].id` as 'packs[0].id'}
          defaultValue={item.id}
          control={control}
        />
        <Controller
          render={({ field }) => <input type="hidden" {...field} />}
          name={`packs[${index}].pack_product.remote_id` as 'packs[0].pack_product.remote_id'}
          defaultValue={isBlank(item.pack_product.remote_id) ? '' : item.pack_product.remote_id}
          control={control}
        />
        <Controller
          render={({ field }) => <input type="hidden" {...field} />}
          name={`packs[${index}].pack_product.tracks_inventory` as 'packs[0].pack_product.tracks_inventory'}
          defaultValue={item.pack_product.tracks_inventory}
          control={control}
        />
        <Controller
          render={({ field }) => <input type="hidden" {...field} />}
          name={`packs[${index}].pack_product.images` as 'packs[0].pack_product.images'}
          defaultValue={item.pack_product.images}
          control={control}
        />
        <FormLayout>
          <FormLayout.Group>
            <Controller
              name={`packs[${index}].pack_qty` as 'packs[0].pack_qty'}
              control={control}
              defaultValue={String(item.pack_qty)}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Quantity in the pack"
                  type="number"
                  autoComplete="off"
                  focused={isBlank(item.pack_product.remote_id)}
                  value={String(value)}
                  error={errors?.packs?.[index]?.pack_qty && 'should not be blank'}
                  onChange={(qty: string) => {
                    if (qty) {
                      setValue(`packs[${index}].pack_product.name`, `${product.name} (${qty} Pack)`, {
                        shouldValidate: true,
                      });
                      product.variants.forEach((variant: IProductVariant, variantIndex: number) => {
                        setValue(
                          `packs[${index}].pack_product.variants[${variantIndex}].cost`,
                          variant.cost ? String(round(parseFloat(qty) * Number(variant.cost), 2)) : '',
                          { shouldValidate: true },
                        );
                        setValue(
                          `packs[${index}].pack_product.variants[${variantIndex}].price`,
                          variant.price
                            ? String(
                                round(
                                  parseFloat(qty) * Number(variant.price) * (1 - Number(settings.pack_discount) / 100),
                                  2,
                                ),
                              )
                            : '',
                          { shouldValidate: true },
                        );
                      });
                    }
                    onChange(qty);
                  }}
                />
              )}
            />
            <Controller
              name={`packs[${index}].pack_product.name` as 'packs[0].pack_product.name'}
              control={control}
              defaultValue={item.pack_product.name}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Pack Product Title"
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  error={errors?.packs?.[index]?.pack_product?.name && 'should not be blank'}
                />
              )}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Controller
              name={`packs[${index}].pack_product.display_name` as 'packs[0].pack_product.display_name'}
              control={control}
              defaultValue={item.pack_product.display_name}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Custom Title (optional)"
                  autoComplete="off"
                  helpText="If you leave this blank, the product name will be used on the pack buttons in your storefront."
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
      {item.pack_product.variants.map((variant: IProductVariant, variantIndex: number) => {
        const currentPack = currentValues[index].pack_product;
        const currentPackVariant =
          currentPack && currentPack.variants.find((v: IProductVariant) => v.name === variant.name);

        return (
          <Card.Section
            key={variant.id}
            title={
              multiVariantProduct(product) ? (
                <Stack spacing="none" alignment="center">
                  {newPack(item.pack_product) && (
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Checkbox label="" checked={value} onChange={onChange} />
                      )}
                      name={
                        `packs[${index}].pack_product.variants[${variantIndex}].enabled` as 'packs[0].pack_product.variants[0].enabled'
                      }
                      defaultValue
                      control={control}
                    />
                  )}
                  <Subheading>{`Variant: ${variant.name}`}</Subheading>
                </Stack>
              ) : null
            }
          >
            {currentPackVariant &&
              (!newPack(item.pack_product) || currentPackVariant.enabled || !multiVariantProduct(product)) && (
                <div>
                  <Controller
                    render={({ field }) => <input type="hidden" {...field} />}
                    name={
                      `packs[${index}].pack_product.variants[${variantIndex}].remote_id` as 'packs[0].pack_product.variants[0].remote_id'
                    }
                    defaultValue={isBlank(variant.remote_id) ? '' : variant.remote_id}
                    control={control}
                  />
                  <Controller
                    render={({ field }) => <input type="hidden" {...field} />}
                    name={
                      `packs[${index}].pack_product.variants[${variantIndex}].name` as 'packs[0].pack_product.variants[0].name'
                    }
                    defaultValue={variant.name}
                    control={control}
                  />
                  <Controller
                    render={({ field }) => <input type="hidden" {...field} />}
                    name={
                      `packs[${index}].pack_product.variants[${variantIndex}].tracks_inventory` as 'packs[0].pack_product.variants[0].tracks_inventory'
                    }
                    defaultValue={variant.tracks_inventory}
                    control={control}
                  />
                  <Controller
                    render={({ field }) => <input type="hidden" {...field} />}
                    name={
                      `packs[${index}].pack_product.variants[${variantIndex}].image` as 'packs[0].pack_product.variants[0].image'
                    }
                    defaultValue={variant.image}
                    control={control}
                  />
                  <FormLayout>
                    <TextContainer>{variant && inventoryAvailable(variant)}</TextContainer>
                    <FormLayout.Group>
                      <Controller
                        name={
                          `packs[${index}].pack_product.variants[${variantIndex}].sku` as 'packs[0].pack_product.variants[0].sku'
                        }
                        control={control}
                        defaultValue={variant.sku}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="SKU (Stock Keeping Unit)"
                            autoComplete="off"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <Controller
                        name={
                          `packs[${index}].pack_product.variants[${variantIndex}].barcode` as 'packs[0].pack_product.variants[0].barcode'
                        }
                        control={control}
                        defaultValue={variant.barcode}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Barcode (ISBN, UPC, GTIN, etc.)"
                            autoComplete="off"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group condensed>
                      <Controller
                        name={
                          `packs[${index}].pack_product.variants[${variantIndex}].price` as 'packs[0].pack_product.variants[0].price'
                        }
                        control={control}
                        defaultValue={variant.price}
                        rules={{ required: true, pattern: CurrencyRegex }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Price"
                            autoComplete="off"
                            prefix="$"
                            value={value}
                            onChange={onChange}
                            error={
                              errors?.packs?.[index]?.pack_product?.variants?.[variantIndex]?.price && 'is invalid'
                            }
                          />
                        )}
                      />
                      <Controller
                        name={
                          `packs[${index}].pack_product.variants[${variantIndex}].cost` as 'packs[0].pack_product.variants[0].cost'
                        }
                        control={control}
                        defaultValue={variant.cost}
                        rules={{ pattern: CurrencyRegex }}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Cost per item"
                            autoComplete="off"
                            prefix="$"
                            value={value}
                            onChange={onChange}
                            error={errors?.packs?.[index]?.pack_product?.variants?.[variantIndex]?.cost && 'is invalid'}
                          />
                        )}
                      />
                      <Stack vertical>
                        <span>Margin</span>
                        <span>
                          {currentPackVariant &&
                            formatValue(calculateMargin(currentPackVariant.cost, currentPackVariant.price), {
                              suffix: '%',
                            })}
                        </span>
                      </Stack>
                      <Stack vertical>
                        <span>Profit</span>
                        <span>
                          {currentPackVariant &&
                            formatValue(calculateProfit(currentPackVariant.cost, currentPackVariant.price), {
                              prefix: '$',
                            })}
                        </span>
                      </Stack>
                    </FormLayout.Group>
                  </FormLayout>
                </div>
              )}
          </Card.Section>
        );
      })}
    </Card>
  );
}

PackProduct.defaultProps = {
  pack: null,
};

export default PackProduct;
